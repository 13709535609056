module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 't2xh3w5p',
    dataset: process.env.GATSBY_SANITY_DATASET || 'development',
  },
  gatsby: {
    siteTitle: 'Academy of Art University Spring Show',
    siteDescription:
      'Spring Show showcases the achievements of graduating students across 22 different schools at Academy of Art University. Meet the future of art and design.',
    siteUrl: 'https://2021springshow.academyart.edu', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
    userTwitter: '@academy_of_art', // Change for Twitter Cards
    author: 'Academy of Art University', // Author for RSS author segment and SEO schema
    authorUrl: 'https://www.academyart.edu', // URL used for author and publisher schema, can be a social profile or other personal site
    shortTitle: 'Academy of Art University Spring Show', // Used for App manifest e.g. Mobile Home Screen
  },
};
